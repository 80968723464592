<template>
 <div class="lg:max-w-7xl lg:mx-auto lg:py-4">
  <div
   class="lg:grid lg:grid-cols-4 lg:gap-8 sm:space-y-0 lg:space-y-0 md:mx-4"
  >
   <div class="col-span-4 flex justify-between mb-4">
    <div class="text-xs font-medium text-gray-700">
     <div class="fixed z-1 left-28">
      <span
       class="inline-flex items-center rounded-md bg-teal-500 px-2 py-1 text-md font-medium text-green-100 ring-1 ring-inset ring-green-100 ring-opacity-20"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-4 h-4 mr-1"
       >
        <path
         d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z"
        />
       </svg>
       {{ $t("beta") }}
      </span>
     </div>
     <div
      class="fixed z-10"
      @mouseover="showDatePickers = true"
      @mouseleave="showDatePickers = false"
     >
      <div class="flex items-center gap-4">
       <div
        class="flex items-center bg-white shadow-md rounded-md border-gray-400 z-10"
       >
        <p class="border-r px-2 py-2">
         {{
          toLocaleDateString(startDate) +
           (selectedPeriod !== "day" ? " - " + toLocaleDateString(endDate) : "")
         }}
        </p>
        <div
         @click="showDatePickers = true"
         class="px-2 py-2 cursor-pointer bg-gray-400 border-gray-400 text-white hover:bg-blue-400 rounded-r-md"
        >
         <font-awesome-icon icon="calendar" class="flex-shrink-0" />
        </div>
       </div>
      </div>
     </div>
    </div>

    <!-- <div class="text-xs font-medium text-gray-700 mr-52">
     <div class="fixed z-10 flex gap-4">
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       Total des appels
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
     </div>
    </div> -->
   </div>
   <div class="col-span-4" v-if="pbxElements && pbxElements?.length">
    <div v-if="showLess" class="lg:grid grid-cols-8 gap-2">
     <span
      v-for="element in pbxElements.slice(0, 8)"
      :key="element"
      class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700"
     >
      <span class="truncate" :title="element">
       {{ element }} -
       {{
        urlType && pbxMap
         ? urlType === "extension"
           ? pbxMap?.extensions[element]
           : urlType === "queue"
           ? pbxMap?.call_queues[element]?.name
           : ""
         : ""
       }}
      </span>
     </span>
     <!-- <div
    v-for="element in pbxElements.slice(0, 12)"
    :key="element"
    class="relative rounded-lg border border-gray-300 bg-whv-if="showLess"ite px-2 py-1 shadow-sm flex items-center hover:border-gray-400">
    <div @click="elementRemoved(element)" class="flex-1 min-w-0">
     <a
      :title="`Remove - ${element}`"
      class="focus:outline-none cursor-pointer">
      <span class="absolute inset-0" aria-hidden="true" />
      <p class="text-xs font-normal text-gray-900 truncate">
       {{ element }}
      </p>
     </a>
    </div>
   </div> -->
    </div>
    <div v-else class="lg:grid grid-cols-8 gap-2">
     <span
      v-for="element in pbxElements"
      :key="element"
      class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700"
     >
      <span class="truncate" :title="element">
       {{ element }} -
       {{
        urlType && pbxMap
         ? urlType === "extension"
           ? pbxMap?.extensions[element]
           : urlType === "queue"
           ? pbxMap?.call_queues[element]?.name
           : ""
         : ""
       }}
      </span>
     </span>
    </div>
    <div v-show="pbxElements.length > 12" class="my-1">
     <span
      v-show="showLess"
      class="text-xs italic text-gray-500 flex justify-center"
     >
      {{ $t("andNumberMoreElements", { numb: pbxElements.length - 8 }) }}
     </span>
     <div class="flex justify-center mt-2">
      <button
       @click="showLess = !showLess"
       class="rounded-lg text-gray-700 bg-white hover:bg-grey-100 border-gray-300 hover:bg-gray-50 hover:shadow-md shadow-sm px-2 py-1 border text-xs"
      >
       {{ showLess ? $t("showMore") : $t("showLess") }}
      </button>
     </div>
    </div>
   </div>
   <!-- stats total calls cards -->
   <div
    v-if="
     totalCallsInboundPbx ||
      totalCallsInternalPbx ||
      totalCallsOutboundPbx ||
      talkingInboundPbx ||
      talkingInternalPbx ||
      talkingOutboundPbx
    "
    class="lg:col-span-4 sm:col-span-1 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-total"
   >
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md"
    >
     <h2 class="uppercase text-lg font-bold mb-">total des appels</h2>
    </div>
    <div
     class="lg:flex justify-center space-y-6 lg:space-y-0 lg:space-x-6"
     v-if="totalCallsArrPbx && totalCallsArrDurationPbx"
     :key="totalCallsArrPbx && totalCallsArrDurationPbx"
    >
     <TotalCallsCard
      :key="totalCallsArrPbx"
      title="Nombre"
      :duration="false"
      :inboundCalls="totalCallsInboundPbx"
      :outboundCalls="totalCallsOutboundPbx"
      :internalCalls="totalCallsInternalPbx"
      :totalCallsArr="totalCallsArrPbx"
     />
     <TotalCallsCard
      :key="totalCallsArrDurationPbx"
      title="Durée"
      :duration="true"
      :inboundCalls="talkingInboundPbx"
      :outboundCalls="talkingOutboundPbx"
      :internalCalls="talkingInternalPbx"
      :totalCallsArr="totalCallsArrDurationPbx"
     />
    </div>

    <div class="pt-4">
     <div class="flex justify-end items-end">
      <span class="text-sm text-gray-700">
       * Appels émis vers l'extérieur et appels reçus depuis l'extérieur
      </span>
     </div>
     <!-- <div class="flex justify-end items-end">
      <span class="text-sm text-gray-700"> ** File uniquement </span>
     </div> -->
    </div>
   </div>
   <!-- stats incomming calls cards -->
   <!-- v-if="dataQueueTable?.length && urlType !== 'extension'" -->
   <div
    v-if="totalCalls"
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-incomming"
   >
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mb-6"
    >
     <h2 class="uppercase text-lg font-bold mb-">
      APPELS reçus
      {{
       urlType === "extension"
        ? "par mes utilisateurs"
        : "SUR MES FILES D`ATTENTES"
      }}
     </h2>
    </div>
    <div class="lg:grid grid-cols-3 lg:space-x-4 space-y-6 lg:space-y-0">
     <lost-calls-card
      v-if="totalCalls"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10"
     />
     <avg-answer-time-card
      v-if="totalCalls && waiting"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10"
      :urlType="urlType"
     />
     <ten-seconds-calls-card
      v-if="totalCalls && talking_10"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10"
     />
    </div>
    <!-- stats duration total / duration avg / longest call  -->
    <div
     v-if="dataColumnChart?.length && dataColumnChartCategorie?.length"
     class="col-span-4 bg-white rounded-lg py-2"
    >
     <div class="flex justify-between"></div>
    </div>
    <div class="lg:grid grid-cols-subgrid col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="dataColumnChart.length && dataColumnChartHour.length"
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour' ? dataColumnChartHour : dataColumnChart
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHourCategorie
        : dataColumnChartCategorie
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChart)"
      :totalUnanswered="this.getUnansweredNumb(dataColumnChart)"
      :selectedValue="answerType"
      @input="answerType = $event"
     />
    </div>
    <div class="lg:grid lg:space-y-0 sm:space-y-8 grid-cols-4 lg:gap-2 text-sm">
     <div
      v-if="talking && totalCalls"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels reçus est de
         <span class="">
          {{
           (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[0])
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
            (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[1])
             ? parseInt(
                formatSecsToHourMinAndSecsNoDays(talking).split(":")[1]
               ) + "min"
             : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[0]
              ) + "h"
            : "") +
            " " +
            (parseInt(
             formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
              ":"
             )[1]
            )
             ? parseInt(
                formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                 ":"
                )[1]
               ) + "min"
             : "") +
            " " +
            (parseInt(
             formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
              ":"
             )[2]
            )
             ? parseInt(
                formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                 ":"
                )[2]
               ) + "sec"
             : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ lowestRateHour.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(lowestRateHour.split(":")[0]) + 1 + "0").slice(0, 2)
           }}h </span
          >, {{ (100 - worstAnsweredRate).toFixed(0) }}% des appels ne sont pas
          décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ bestRateHour.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(bestRateHour.split(":")[0]) + 1 + "0").slice(0, 2)
           }}h </span
          >, {{ bestAnsweredRate.toFixed(0) }}% des appels sont décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ mostCallsHour.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(mostCallsHour.split(":")[0]) + 1 + "0").slice(0, 2)
           }}h </span
          >,
          {{ mostCalls }}
          ont été reçus .
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre
       {{ parseInt(pbxTimeSelected[0].slice(0, 2)) }}h et
       {{ parseInt(pbxTimeSelected[1].slice(0, 2)) }}h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats outgoing calls cards -->
   <div
    v-if="
     dataColumnChartOutbound?.length && dataColumnChartOutboundHour?.length
    "
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-outgoing"
   >
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md"
    >
     <h2 class="uppercase text-lg font-bold mb-">appels émis externe</h2>
    </div>
    <div class="col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="
       dataColumnChartOutbound.length &&
        dataColumnChartOutboundHour.length &&
        dataColumnChartOutboundHourCategorie.length &&
        dataColumnChartCategorieOutbound.length
      "
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour'
        ? dataColumnChartOutboundHour
        : dataColumnChartOutbound
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartOutboundHourCategorie
        : dataColumnChartCategorieOutbound
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChartOutbound)"
      :totalUnanswered="this.getUnansweredNumb(dataColumnChartOutbound)"
      :selectedValue="answerType"
      @input="answerType = $event"
     />
    </div>
    <div class="lg:grid grid-cols-4 gap-2 text-sm">
     <div
      v-if="talkingOutbound && totalCallsOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels émis est de
         <span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
            (parseInt(
             formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
            )
             ? parseInt(
                formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
               ) + "min"
             : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[0]
              ) + "h"
            : "") +
            " " +
            (parseInt(
             formatSecsToHourMinAndSecsNoDays(
              talkingOutbound / totalCallsOutbound
             ).split(":")[1]
            )
             ? parseInt(
                formatSecsToHourMinAndSecsNoDays(
                 talkingOutbound / totalCallsOutbound
                ).split(":")[1]
               ) + "min"
             : "") +
            " " +
            (parseInt(
             formatSecsToHourMinAndSecsNoDays(
              talkingOutbound / totalCallsOutbound
             ).split(":")[2]
            )
             ? parseInt(
                formatSecsToHourMinAndSecsNoDays(
                 talkingOutbound / totalCallsOutbound
                ).split(":")[2]
               ) + "sec"
             : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ lowestRateHourOutbound.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(lowestRateHourOutbound.split(":")[0]) + 1 + "0").slice(
             0,
             2
            )
           }}h </span
          >, {{ (100 - worstAnsweredRateOutbound).toFixed(0) }}% des appels ne
          sont pas décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ bestRateHourOutbound.split(":")[0] }}
          </span>
          et
          <span class="">
           {{
            (parseInt(bestRateHourOutbound.split(":")[0]) + 1 + "0").slice(0, 2)
           }} </span
          >, {{ bestAnsweredRateOutbound.toFixed(0) }}% des appels sont
          décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2"
     >
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ mostCallsHourOutbound.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (
             parseInt(mostCallsHourOutbound.split(":")[0].split(":")[0]) +
             1 +
             "0"
            ).slice(0, 2)
           }}h </span
          >, {{ mostCallsOutbound }} ont été émis.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre
       {{ parseInt(pbxTimeSelected[0].slice(0, 2)) }}h et
       {{ parseInt(pbxTimeSelected[1].slice(0, 2)) }}h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats top 10 users -->
   <div
    v-if="dataColumnChartTopUsers?.length"
    class="col-span-4 pb-12 space-y-2 py-2 bg-white shadow-md px-2 rounded-sm"
    id="stats-top"
   >
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0"
    >
     <h2 class="uppercase text-lg font-bold">Top 10 utilistateurs</h2>
    </div>
    <!-- <div
     class="px-2 py-2 text-sm bg-blue-100 rounded-lg shadow-md text-blue-800 mx-2 -mb-4">
     Voici un aperçu des dix
     <span class="font-medium">principaux utilisateurs</span> en fonction du
     nombre d'appels entrants et sortants auxquels ils ont répondu. Les données
     révèlent une répartition diversifiée de l'activité téléphonique parmi ces
     utilisateurs, avec certains occupant des positions plus élevées en raison
     de leur <span class="font-medium">engagement accru dans les appels</span>.
     Ce classement offre un aperçu précieux de l'utilisation des communications
     au sein de notre réseau, mettant en lumière les membres les plus actifs et
     les tendances d'utilisation dominantes.
    </div> -->

    <TopUsersCard
     :key="dataColumnChartTopUsers"
     v-if="
      dataColumnChartTopUsers.length && dataColumnChartTopUsersLabels.length
     "
     @quickPeriod="quickPeriodAnswered = $event"
     :quickPeriod="quickPeriodAnswered"
     @selectPeriod="periodSelectorAnswered = $event"
     :seriesForColumn="dataColumnChartTopUsers"
     :categoriesForColumn="dataColumnChartTopUsersLabels"
    />
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (1) Appels externes reçus ayant été décrochés par l'utilisateur. Les
      appels non décrochés ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (2) Appels externes émis par l'utilisateur et répondus par l'appelé. Les
      appels non répondus ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (3) Appels internes émis ou reçus par l'utilisateur ayant été répondus.
      Les appels non répondus ne sont pas pris en compte.
     </span>
    </div>
   </div>
   <!-- <div class="col-span-1" v-if="dataQueueTable.length">
    <TopQueuesCard :data="dataQueueTable" />
   </div> -->
  </div>

  <!-- <div
   v-if="dataAnswerTime.length && labelAnswerTime.length && dataQueueTable.length > 0 && dataSeriesOrigin.length && dataSeriesOrigin[0] && dataSeriesOrigin[0].data && dataSeriesOriginTop.length && dataSeriesOriginTop[0] && dataSeriesOriginTop[0].data"
   class="lg:flex lg:gap-4 sm:space-y-4 lg:space-y-0"
  >
   <QueueAnswerTimeCard :series="dataAnswerTime" :labels="labelAnswerTime" />
   <OriginsCard :key="dataSeriesOrigin[0]" v-show="dataSeriesOrigin[0].data" :data="dataSeriesOrigin[0].data" @quickPeriod="quickPeriodOrigin = $event" :quickPeriod="quickPeriodOrigin" @selectPeriod="periodSelectorOrigin = $event" />
   <OriginsTopCard :key="dataSeriesOriginTop[0]" v-show="dataSeriesOriginTop[0].data" :data="dataSeriesOriginTop[0].data" @quickPeriod="quickPeriodOriginTop = $event" :quickPeriod="quickPeriodOriginTop" @selectPeriod="periodSelectorOriginTop = $event" />
  </div> -->
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";
import TopUsersCard from "../../components/PBX/Dashboard/TopUsersCard.vue";
import TenSecondsCallsCard from "../../components/PBX/Dashboard/TenSecondsCallsCard.vue";
import TotalCallsCard from "../../components/PBX/Dashboard/TotalCallsCard.vue";
import QueueAnswerTimeCard from "../../components/PBX/Dashboard/QueueAnswerTimeCard.vue";
import TopQueuesCard from "../../components/PBX/Dashboard/TopQueuesCard.vue";
import AnsweredCard from "../../components/PBX/Dashboard/AnsweredCard.vue";
import OriginsCard from "../../components/PBX/Dashboard/OriginsCard.vue";
import OriginsTopCard from "../../components/PBX/Dashboard/OriginsTopCard.vue";
import AvgAnswerTimeCard from "../../components/PBX/Dashboard/AvgAnswerTimeCard.vue";
import LostCallsCard from "../../components/PBX/Dashboard/LostCallsCard.vue";

const elementTypes = {
 0: "extension",
 1: "world",
 2: "undefined (2)",
 4: "queue",
 5: "voicemail",
 6: "ivr",
 8: "undefined (8)",
 9: "undefined (9)",
 12: "undefined (12)",
 13: "external_number_no_success",
 14: "call_flow",
 999: "not provided",
};

const calendarOptions = [
 {
  name: "day",
  value: "day",
  icon: "calendar-day",
 },
 {
  name: "this week",
  value: "currentWeek",
  icon: "calendar-week",
 },
 {
  name: "last week",
  value: "lastWeek",
  icon: "calendar-week",
 },
 {
  name: "this month",
  value: "currentMonth",
  icon: "calendar",
 },
 {
  name: "last month",
  value: "lastMonth",
  icon: "calendar",
 },
 {
  name: "last 3 months",
  value: "last3Months",
  icon: "calendar",
 },
 {
  name: "last 6 months",
  value: "last6Months",
  icon: "calendar",
 },
 {
  name: "personnalisé",
  value: "custom",
  icon: "calendar",
 },
];

export default {
 props: ["presentationKey"],
 components: {
  EmptyStateDashboard,
  AnsweredCard,
  OriginsCard,
  OriginsTopCard,
  TopQueuesCard,
  QueueAnswerTimeCard,
  TotalCallsCard,
  AvgAnswerTimeCard,
  LostCallsCard,
  TenSecondsCallsCard,
  TopUsersCard,
 },
 data() {
  return {
   urlType: null,
   currentMonthName: "",
   lastMonthName: "",
   currentYear: "",

   totalCalls: null, // Current month's data
   carCalls: null, // Current month's data
   answeredCalls: null, // Current month's data
   unansweredCalls: null, // Current month's data
   averageWaitTime: null, // Current month's data

   // Previous month's data
   prevTotalCalls: null,
   prevCarCalls: null,
   prevAnsweredCalls: null,
   prevUnansweredCalls: null,
   prevAverageWaitTime: null,

   // Calculate trends
   answeredTrend: 0,
   unansweredTrend: 0,
   waitTimeTrend: 0,
   carTrend: 0,
   totalTrend: 0,

   totalCallsInboundPbx: 0,
   totalCallsInternalPbx: 0,
   totalCallsOutboundPbx: 0,
   talkingInboundPbx: 0,
   talkingInternalPbx: 0,
   talkingOutboundPbx: 0,
   totalCallsArrPbx: [0, 0, 0],
   totalCallsArrDurationPbx: [0, 0, 0],
   alertList: false,
   reportList: false,
   customDate: null,
   showDatePickers: false,
   period: "",
   calendarOptions,
   selectedPeriod: "",
   answerType: "hour",
   elementTypes,
   quickPeriodAnswered: "last 30 days",
   quickPeriodOrigin: "last 30 days",
   quickPeriodOriginTop: "last 30 days",
   defaultPeriod: 30,
   periodSelectorAnswered: null,
   periodSelectorOrigin: null,
   periodSelectorOriginTop: null,
   dataColumnChartMonthLabels: [],
   dataColumnChart: [],
   dataColumnChartCategorie: [],
   dataColumnChartMonth: [],
   dataColumnChartMonthWaiting: [],
   dataColumnChartMonthWaitingLabels: [],
   dataColumnChartHour: [],
   dataColumnChartHourCategorie: [],
   dataColumnChartHourOutbound: [],
   dataSeriesOrigin: [],
   dataSeriesOriginTop: [],
   dataColumnChartTopUsers: [],
   dataColumnChartTopUsersLabels: [],
   dataColumnChartOutboundHour: [],
   dataColumnChartOutboundHourCategorie: [],
   dataColumnChartOutbound: [],
   dataColumnChartCategorieOutbound: [],
   dataQueueTable: [],
   dataAnswerTime: [],
   labelAnswerTime: [],
   data: undefined,
   worstAnsweredRate: null,
   bestAnsweredRate: null,
   mostCalls: null,
   totalCalls: 0,
   totalCallsOutbound: 0,
   lostCalls: 0,
   lostCallsOutbound: 0,
   answered_15: 0,
   talking_10: 0,
   waiting: 0,
   waitingOutbound: 0,
   talkingOutbound: 0,
   talking: 0,
   answering: 0,
   answeringOutbound: 0,
   mostCallsHour: "",
   mostCallsHourOutbound: "",
   bestRateHour: "",
   bestRateHourOutbound: "",
   lowestDisuadedRateHour: "",
   lowestAbandonedRateHour: "",
   lowestRateHour: "",
   lowestRateHourOutbound: "",
   worstAnsweredRateOutbound: null,
   mostCallsOutbound: null,
   bestAnsweredRateOutbound: null,
   totalCallsArr: [0, 0],
   totalCallsArrDuration: [0, 0],
   startDate: null,
   endDate: null,
   thead: [],
   tbody: [],
   theadAlert: [],
   tbodyAlert: [],
   currentSort: "id",
   currentSortDir: "asc",
   dataReports: undefined,
   dataAlerts: undefined,
   page: 1,
   pageAlerts: 1,
   selectedPbxElements: [],
   showLess: true,
   pbxMap: null,
   pbxElements: null,
  };
 },
 methods: {
  async getPbxData() {
   try {
    const res = await axios.get(
     `https://xp-stats-201.bluerock.tel/api/v1.2/reports/${this.presentationKey}`
    );

    const data = res.data;

    if (data) {
     let parameters = data.parameters;
     console.log(parameters);

     this.pbxMap = data?.map ? data.map : null;

     this.$store.dispatch("hostName", parameters.host_name);

     this.$store.dispatch("dataSet", parameters.report_type);

     this.startDate = parameters.start.split(" ")[0];
     this.endDate = parameters.end.split(" ")[0];

     this.pbxElements =
      parameters?.dns === "*" || parameters?.element_type === '*' ? [] : parameters?.dns?.split(",");

     this.urlType = parameters.element_type == 4 ? "queue" : "extension";

     let timeSelected = [];

     if (parameters.time) {
      let splitTime = parameters.time.split(":");
      timeSelected[0] = splitTime[0];
      timeSelected[1] = splitTime[1];
     }
    }

    return data;
   } catch (error) {
    console.log("getPbxData ~ error:", error);
    this.errorHandling(error);
    this.$emit("error404", error.response);
   }
  },
  selectPeriod(value) {
   this.selectedPeriod = value;
  },
  async setPeriod(key) {
   this.answeredCalls = null;
   this.unansweredCalls = null;
   this.averageWaitTime = null;
   this.prevAnsweredCalls = null;
   this.prevUnansweredCalls = null;
   this.prevAverageWaitTime = null;
   this.answeredTrend = 0;
   this.unansweredTrend = 0;
   this.waitTimeTrend = 0;

   this.totalCalls = null;
   this.totalCallsOutbound = null;
   this.lostCalls = null;
   this.lostCallsOutbound = null;
   this.answered_15 = null;
   this.talking_10 = null;
   this.waiting = null;
   this.waitingOutbound = null;
   this.talkingOutbound = null;
   this.talking = null;
   this.answering = null;
   this.answeringOutbound = null;
   this.mostCallsHour = null;
   this.mostCallsHourOutbound = null;
   this.bestRateHour = null;
   this.bestRateHourOutbound = null;
   this.lowestDisuadedRateHour = null;
   this.lowestAbandonedRateHour = null;
   this.lowestRateHour = null;
   this.lowestRateHourOutbound = null;
   this.totalCallsArr = [0, 0];
   this.totalCallsArrDuration = [0, 0];

   this.worstAnsweredRateOutbound = null;
   this.mostCallsOutbound = null;
   this.bestAnsweredRateOutbound = null;

   this.totalCallsInboundPbx = 0;
   this.totalCallsInternalPbx = 0;
   this.totalCallsOutboundPbx = 0;
   this.talkingInboundPbx = 0;
   this.talkingInternalPbx = 0;
   this.talkingOutboundPbx = 0;
   this.totalCallsArrPbx = [0, 0, 0];
   this.totalCallsArrDurationPbx = [0, 0, 0];

   this.dataColumnChart = [];
   this.dataColumnChartCategorie = [];
   this.dataColumnChartMonth = [];
   this.dataColumnChartMonthWaiting = [];
   this.dataColumnChartOutbound = [];
   this.dataColumnChartHour = [];
   this.dataColumnChartHourCategorie = [];
   this.dataSeriesOrigin = [];
   this.dataSeriesOriginTop = [];
   this.dataColumnChartTopUsers = [];
   this.dataColumnChartTopUsersLabels = [];
   this.dataQueueTable = [];
   this.dataAnswerTime = [];
   this.labelAnswerTime = [];

   this.dataColumnChartOutboundHour = [];
   this.dataColumnChartOutboundHourCategorie = [];
   this.dataColumnChartCategorieOutbound = [];

   this.totalCallsArr = [0, 0];
   this.totalCallsArrDuration = [0, 0];

   const data = await this.getPbxData();

   this.setInboundQueuesData(data?.queue)
    .then(() => this.setOutboundData(data?.outbound))
    .then(() => this.setPbxData(data?.pbx))
    .then(() => this.setTopUsers(data?.user))
    // .then(() => this.getDataTopQueues())
    // .then(() => this.getDataFromElementForColumnOutbound())
    .finally(() => this.$emit("setLoading", false));
  },
  async setInboundQueuesData(data) {
   //  const request_data = await this.getPbxData();
   //  const data = request_data?.queue;
   const groupedData = await this.groupCallsData(data);
   this.setInboundQueuesTotalValues(groupedData);
   this.setChartData(groupedData);
  },
  async setOutboundData(data) {
   //  const request_data = await this.getPbxData();
   //  const data = request_data?.outbound;
   const groupedData = await this.groupCallsDataOutbound(data);
   this.setOutboundCallsTotalValues(groupedData);
   this.setChartDataOutbound(groupedData);
  },
  async groupCallsDataOutbound(data) {
   // Arrays to hold grouped data
   const groupedByDay = {};
   const groupedByHour = {};

   // Iterate over each call record
   await data?.forEach(record => {
    const date = record.hour.split(" ")[0]; // Get the date part
    const hour = record.hour.split(" ")[1]; // Get the time part

    // Initialize day grouping
    if (!groupedByDay[date]) {
     groupedByDay[date] = {
      outbound_calls: 0,
      answered_outbound_calls: 0,
      total_talking_duration_in_seconds: 0,
     };
    }
    // Sum up the calls for the day
    groupedByDay[date].outbound_calls += Number(record.outbound_calls);
    groupedByDay[date].answered_outbound_calls += Number(
     record.answered_outbound_calls
    );
    groupedByDay[date].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );

    // Initialize hour grouping
    if (!groupedByHour[hour]) {
     groupedByHour[hour] = {
      outbound_calls: 0,
      answered_outbound_calls: 0,
      total_talking_duration_in_seconds: 0,
     };
    }
    // Sum up the calls for the hour
    groupedByHour[hour].outbound_calls += Number(record.outbound_calls);
    groupedByHour[hour].answered_outbound_calls += Number(
     record.answered_outbound_calls
    );
    groupedByHour[hour].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );
   });

   // Convert objects to arrays for easier use
   const groupedByDayArray = Object.entries(groupedByDay).map(
    ([date, values]) => ({
     date,
     ...values,
    })
   );

   const groupedByHourArray = Object.entries(groupedByHour).map(
    ([hour, values]) => ({
     hour,
     ...values,
    })
   );

   return {
    groupedByDay: groupedByDayArray,
    groupedByHour: groupedByHourArray,
   };
  },
  async groupCallsData(data) {
   // Arrays to hold grouped data
   const groupedByDay = {};
   const groupedByHour = {};

   // Iterate over each call record
   await data?.forEach(record => {
    const date = record.hour.split(" ")[0]; // Get the date part
    const hour = record.hour.split(" ")[1]; // Get the time part

    // Initialize day grouping
    if (!groupedByDay[date]) {
     groupedByDay[date] = {
      inbound_calls: 0,
      answered_inbound_calls: 0,
      total_talking_duration_in_seconds: 0,
      total_waiting_duration_in_seconds: 0,
      ten_seconds_calls: 0,
     };
    }
    // Sum up the calls for the day
    groupedByDay[date].inbound_calls += Number(record.inbound_calls);
    groupedByDay[date].answered_inbound_calls += Number(
     record.answered_inbound_calls
    );
    groupedByDay[date].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );
    groupedByDay[date].total_waiting_duration_in_seconds += Number(
     record.total_waiting_duration_in_seconds
    );
    groupedByDay[date].ten_seconds_calls += Number(record.ten_seconds_calls);

    // Initialize hour grouping
    if (!groupedByHour[hour]) {
     groupedByHour[hour] = {
      inbound_calls: 0,
      answered_inbound_calls: 0,
      total_talking_duration_in_seconds: 0,
      total_waiting_duration_in_seconds: 0,
      ten_seconds_calls: 0,
     };
    }
    // Sum up the calls for the hour
    groupedByHour[hour].inbound_calls += Number(record.inbound_calls);
    groupedByHour[hour].answered_inbound_calls += Number(
     record.answered_inbound_calls
    );
    groupedByHour[hour].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );
    groupedByHour[hour].total_waiting_duration_in_seconds += Number(
     record.total_waiting_duration_in_seconds
    );
    groupedByHour[hour].ten_seconds_calls += Number(record.ten_seconds_calls);
   });

   // Convert objects to arrays for easier use
   const groupedByDayArray = Object.entries(groupedByDay).map(
    ([date, values]) => ({
     date,
     ...values,
    })
   );

   const groupedByHourArray = Object.entries(groupedByHour).map(
    ([hour, values]) => ({
     hour,
     ...values,
    })
   );

   return {
    groupedByDay: groupedByDayArray,
    groupedByHour: groupedByHourArray,
   };
  },
  async setChartData(groupedData) {
   console.log("setChartData ~ groupedData:", groupedData);
   this.setHourChartData(groupedData.groupedByHour);
   this.setDayChartData(groupedData.groupedByDay);
  },
  async setChartDataOutbound(groupedData) {
   this.setHourChartDataOutbound(groupedData.groupedByHour);
   this.setDayChartDataOutbound(groupedData.groupedByDay);
  },
  setHourChartDataOutbound(hourData) {
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   // Generate all hours from 07:00:00 to 16:00:00
   for (let i = startHour; i <= endHour; i++) {
    const hourString = `${String(i).padStart(2, "0")}:00:00`;
    allHours.push(hourString);
   }

   // Create a map for hour data
   const hourMap = {};
   hourData.forEach(item => {
    hourMap[item.hour] = item;
   });

   let categories = [];
   let hourChartSeries = [];

   // Prepare series and categories
   categories = allHours; // Set categories to all hours between 07:00:00 and 16:00:00
   hourChartSeries = [
    {
     name: "Répondus",
     data: allHours.map(hour =>
      hourMap[hour] ? Number(hourMap[hour].answered_outbound_calls) : 0
     ),
    },
    {
     name: "Non répondus",
     data: allHours.map(hour =>
      hourMap[hour]
       ? Number(hourMap[hour].outbound_calls) -
         Number(hourMap[hour].answered_outbound_calls)
       : 0
     ),
    },
   ];

   this.dataColumnChartOutboundHour = hourChartSeries;
   this.dataColumnChartOutboundHourCategorie = categories;
  },
  setHourChartData(hourData) {
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   for (let i = startHour; i <= endHour; i++) {
    const hourString = `${String(i).padStart(2, "0")}:00:00`;
    allHours.push(hourString);
   }
   console.log("setHourChartData ~ allHours:", allHours);

   // Create a map for hour data
   const hourMap = {};
   hourData.forEach(item => {
    hourMap[item.hour] = item;
   });

   let categories = [];
   let hourChartSeries = [];

   // Prepare series and categories
   categories = allHours;
   hourChartSeries = [
    {
     name: "Répondus",
     data: allHours.map(hour =>
      hourMap[hour] ? Number(hourMap[hour].answered_inbound_calls) : 0
     ),
    },
    {
     name: "Non répondus",
     data: allHours.map(hour =>
      hourMap[hour]
       ? Number(hourMap[hour].inbound_calls) -
         Number(hourMap[hour].answered_inbound_calls)
       : 0
     ),
    },
   ];
   this.dataColumnChartHour = hourChartSeries;
   this.dataColumnChartHourCategorie = categories;
  },
  setDayChartDataOutbound(dayData) {
   if (dayData?.length) {
    // Generate complete date range based on the selected period
    const allDays = this.generateDateRange(this.startDate, this.endDate);
    let categories = [];
    let dayChartSeries = [];

    // Create a map for day data
    const dayMap = {};
    dayData?.forEach(item => {
     dayMap[item?.date] = item;
    });

    // Prepare series and categories
    categories = allDays.map(day => day.format("YYYY-MM-DD"));

    dayChartSeries = [
     {
      name: "Répondus",
      data: allDays.map(day =>
       dayMap[day.format("YYYY-MM-DD")]
        ? Number(dayMap[day.format("YYYY-MM-DD")].answered_outbound_calls)
        : 0
      ),
     },
     {
      name: "Non répondus",
      data: allDays.map(day =>
       dayMap[day.format("YYYY-MM-DD")]
        ? Number(dayMap[day.format("YYYY-MM-DD")].outbound_calls) -
          Number(dayMap[day.format("YYYY-MM-DD")].answered_outbound_calls)
        : 0
      ),
     },
    ];

    this.dataColumnChartOutbound = dayChartSeries;
    this.dataColumnChartCategorieOutbound = categories;
   }
  },
  setDayChartData(dayData) {
   // Generate complete date range
   const allDays = this.generateDateRange(this.startDate, this.endDate);
   let categories = [];
   let dayChartSeries = [];

   // Create a map for day data
   const dayMap = {};
   dayData.forEach(item => {
    dayMap[item?.date] = item;
   });

   // Prepare series and categories
   categories = allDays.map(day => day.format("YYYY-MM-DD"));
   dayChartSeries = [
    {
     name: "Répondus",
     data: allDays.map(day =>
      dayMap[day.format("YYYY-MM-DD")]
       ? Number(dayMap[day.format("YYYY-MM-DD")].answered_inbound_calls)
       : 0
     ),
    },
    {
     name: "Non répondus",
     data: allDays.map(day =>
      dayMap[day.format("YYYY-MM-DD")]
       ? Number(dayMap[day.format("YYYY-MM-DD")].inbound_calls) -
         Number(dayMap[day.format("YYYY-MM-DD")].answered_inbound_calls)
       : 0
     ),
    },
   ];
   this.dataColumnChart = dayChartSeries;
   this.dataColumnChartCategorie = categories;
  },
  setTopUsers(data) {
   // Sort the users by total_calls in descending order and get the top 10
   const topUsers = data
    .sort((a, b) => b.total_calls - a.total_calls)
    .slice(0, 10);

   // Generate labels (display names of the top users)
   const labels = topUsers?.map(user => user.display_name);

   // Prepare the series data
   const series = [
    {
     name: "Reçus",
     data: topUsers.map(user => user.inbound_calls),
    },
    {
     name: "Emis",
     data: topUsers.map(user => user.outbound_calls),
    },
    {
     name: "Internes",
     data: topUsers?.map(
      user => user.internal_calls_made + user.internal_calls_received
     ),
    },
   ];

   // Assign data to chart properties
   this.dataColumnChartTopUsers = series;
   this.dataColumnChartTopUsersLabels = labels;
  },
  setPbxData(data) {
   // const request_data = this.getPbxData();
   // const data = request_data?.pbx;
   // Initialize variables to ensure they get values from the provided data
   let inboundCalls = 0,
    outboundCalls = 0,
    internalCalls = 0;
   let inboundDuration = 0,
    outboundDuration = 0,
    internalDuration = 0;

   // Iterate through the data array to extract the values based on 'call_way'
   data?.forEach(item => {
    switch (item.call_way) {
     case "inbound":
      inboundCalls = parseInt(item.unique_calls); // Total inbound calls
      inboundDuration = parseInt(item.total_duration_seconds); // Inbound call duration
      break;
     case "outbound":
      outboundCalls = parseInt(item.unique_calls); // Total outbound calls
      outboundDuration = parseInt(item.total_duration_seconds); // Outbound call duration
      break;
     case "internal":
      internalCalls = parseInt(item.unique_calls); // Total internal calls
      internalDuration = parseInt(item.total_duration_seconds); // Internal call duration
      break;
     // You can add more cases if needed (e.g., 'transfer') but they're not used in your current logic
     default:
      break;
    }
   });

   // Set the extracted data
   this.totalCallsInboundPbx = inboundCalls;
   this.totalCallsInternalPbx = internalCalls;
   this.totalCallsOutboundPbx = outboundCalls;
   this.talkingInboundPbx = inboundDuration;
   this.talkingInternalPbx = internalDuration;
   this.talkingOutboundPbx = outboundDuration;

   // Prepare arrays for calls and durations
   this.totalCallsArrPbx = [
    this.totalCallsInboundPbx,
    this.totalCallsOutboundPbx,
    this.totalCallsInternalPbx,
   ];

   this.totalCallsArrDurationPbx = [
    this.talkingInboundPbx,
    this.talkingOutboundPbx,
    this.talkingInternalPbx,
   ];
  },
  setInboundQueuesTotalValues(data) {
   let totalTenSecondsCalls = 0;
   let totalWaitingDurationInSeconds = 0;
   let totalTalkingDurationInSeconds = 0;
   let totalInboundCalls = 0;
   let totalAnsweredInboundCalls = 0;

   let hourWithWorstAnsweredRate = null;
   let worstAnsweredRate = Infinity;

   let hourWithBestAnsweredRate = null;
   let bestAnsweredRate = -Infinity;

   let hourWithMostCalls = null;
   let mostCalls = 0;

   // Loop through the data to calculate total values
   data?.groupedByHour?.forEach(entry => {
    // Add to total ten seconds calls and waiting duration
    totalTenSecondsCalls += entry.ten_seconds_calls;
    totalWaitingDurationInSeconds += entry.total_waiting_duration_in_seconds;
    totalTalkingDurationInSeconds += entry.total_talking_duration_in_seconds;
    totalInboundCalls += entry.inbound_calls;
    totalAnsweredInboundCalls += entry.answered_inbound_calls;

    // Calculate answered rate for the current hour
    const answeredRate =
     (entry.answered_inbound_calls / entry.inbound_calls) * 100;

    // Check for worst answered rate
    if (answeredRate < worstAnsweredRate) {
     worstAnsweredRate = answeredRate;
     hourWithWorstAnsweredRate = entry.hour;
    }

    // Check for best answered rate
    if (answeredRate > bestAnsweredRate) {
     bestAnsweredRate = answeredRate;
     hourWithBestAnsweredRate = entry.hour;
    }

    // Check for the hour with most calls
    if (entry.inbound_calls > mostCalls) {
     mostCalls = entry.inbound_calls;
     hourWithMostCalls = entry.hour;
    }
   });

   // Calculate the total number of unanswered calls
   const totalUnansweredCalls = totalInboundCalls - totalAnsweredInboundCalls;

   // Calculate average waiting duration
   const avgWaitingDurationInSeconds =
    totalWaitingDurationInSeconds / totalAnsweredInboundCalls;
   console.log(avgWaitingDurationInSeconds);

   this.totalCalls = totalInboundCalls;
   this.lostCalls = totalUnansweredCalls;
   this.answered_15 = avgWaitingDurationInSeconds.toFixed(0);
   this.talking_10 = totalTenSecondsCalls;
   this.waiting = totalWaitingDurationInSeconds;
   this.talking = totalTalkingDurationInSeconds;
   this.mostCallsHour = hourWithMostCalls;
   this.bestRateHour = hourWithBestAnsweredRate;
   this.lowestRateHour = hourWithWorstAnsweredRate;
   this.worstAnsweredRate = worstAnsweredRate;
   this.bestAnsweredRate = bestAnsweredRate;
   this.mostCalls = mostCalls;
  },
  setOutboundCallsTotalValues(data) {
   console.log("setOutboundCallsTotalValues ~ data:", data);
   let totalTalkingDurationInSeconds = 0;
   let totalOutboundCalls = 0;
   let totalAnsweredOutboundCalls = 0;

   let hourWithWorstAnsweredRate = null;
   let worstAnsweredRate = Infinity;

   let hourWithBestAnsweredRate = null;
   let bestAnsweredRate = -Infinity;

   let hourWithMostCalls = null;
   let mostCalls = 0;

   // Loop through the data to calculate total values
   data?.groupedByHour?.forEach(entry => {
    // Add to total ten seconds calls and waiting duration
    totalTalkingDurationInSeconds += entry.total_talking_duration_in_seconds;
    totalOutboundCalls += entry.outbound_calls;
    totalAnsweredOutboundCalls += entry.answered_outbound_calls;

    // Calculate answered rate for the current hour
    const answeredRate =
     (entry.answered_outbound_calls / entry.outbound_calls) * 100;

    // Check for worst answered rate
    if (answeredRate < worstAnsweredRate) {
     worstAnsweredRate = answeredRate;
     hourWithWorstAnsweredRate = entry.hour;
    }

    // Check for best answered rate
    if (answeredRate > bestAnsweredRate) {
     bestAnsweredRate = answeredRate;
     hourWithBestAnsweredRate = entry.hour;
    }

    // Check for the hour with most calls
    if (entry.outbound_calls > mostCalls) {
     mostCalls = entry.outbound_calls;
     hourWithMostCalls = entry.hour;
    }
   });

   // Calculate the total number of unanswered calls
   const totalUnansweredCalls = totalOutboundCalls - totalAnsweredOutboundCalls;

   this.totalCallsOutbound = totalOutboundCalls;
   this.lostCallsOutbound = totalUnansweredCalls;
   this.talkingOutbound = totalTalkingDurationInSeconds;
   this.mostCallsHourOutbound = hourWithMostCalls;
   this.bestRateHourOutbound = hourWithBestAnsweredRate;
   this.lowestRateHourOutbound = hourWithWorstAnsweredRate;
   this.worstAnsweredRateOutbound = worstAnsweredRate;
   this.mostCallsOutbound = mostCalls;
   this.bestAnsweredRateOutbound = bestAnsweredRate;
  },
 },
 mounted() {
  this.setPeriod(this.presentationKey);
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxTimeSelected",
   "hostName",
   "hostTimeZone",
   "dataSet",
  ]),
 },
 watch: {},
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
