<template>
  <div
   class=" rounded-lg p-6 flex flex-col items-center justify-center space-y-6">
   <div class="w-full flex justify-center" id="chart">
    <apexchart
     width="350px"
     :options="duration ? chartOptionsDuration : chartOptions"
     :series="series"></apexchart>
   </div>
   <div
    class="lg:flex lg:justify-around items-center w-full gap-4 text-center space-y-4 lg:space-y-0">
    <div>
     <div class="text-base font-normal text-gray-500">
      <span>Total appels</span>
     </div>
     <div class="leading-none text-xl font-bold text-gray-700">
      <span>
       {{
        duration && outboundCalls + inboundCalls + internalCalls > 0
         ? (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             outboundCalls + inboundCalls + internalCalls
            ).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                outboundCalls + inboundCalls + internalCalls
               ).split(":")[0]
              ) + "h "
            : "") +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             outboundCalls + inboundCalls + internalCalls
            ).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                outboundCalls + inboundCalls + internalCalls
               ).split(":")[1]
              ) + "min"
            : "")
         : outboundCalls + inboundCalls + internalCalls >= 0
         ? outboundCalls + inboundCalls + internalCalls
         : 0
       }}
      </span>
     </div>
    </div>
    <div>
     <div class="text-base font-normal text-gray-500">
      <span>Appels émis*</span>
     </div>
     <div class="leading-none text-xl font-bold text-shamrock">
      <span>
       {{
        duration && outboundCalls > 0
         ? (parseInt(
            formatSecsToHourMinAndSecsNoDays(outboundCalls).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(outboundCalls).split(":")[0]
              ) + "h "
            : "") +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(outboundCalls).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(outboundCalls).split(":")[1]
              ) + "min"
            : "")
         : outboundCalls >= 0
         ? outboundCalls
         : 0
       }}
      </span>
     </div>
    </div>
    <div>
     <div class="text-base font-normal text-gray-500">
      <span>Appels reçus*</span>
     </div>
     <div class="leading-none text-xl font-bold text-piction-blue">
      <span>
       {{
        duration && inboundCalls > 0
         ? (parseInt(
            formatSecsToHourMinAndSecsNoDays(inboundCalls).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(inboundCalls).split(":")[0]
              ) + "h "
            : "") +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(inboundCalls).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(inboundCalls).split(":")[1]
              ) + "min"
            : "")
         : inboundCalls >= 0
         ? inboundCalls
         : 0
       }}
      </span>
     </div>
    </div>
    <div>
     <div class="text-base font-normal text-gray-500">
      <span>Appels internes</span>
     </div>
     <div class="leading-none text-xl font-bold text-yellow-internal">
      <span>
       {{
        duration && internalCalls > 0
         ? (parseInt(
            formatSecsToHourMinAndSecsNoDays(internalCalls).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(internalCalls).split(":")[0]
              ) + "h "
            : "") +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(internalCalls).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(internalCalls).split(":")[1]
              ) + "min"
            : "")
         : internalCalls >= 0
         ? internalCalls
         : 0
       }}
      </span>
     </div>
    </div>
   </div>
  </div>
 </template>
 
 <script>
 export default {
  props: [
   "inboundCalls",
   "outboundCalls",
   "title",
   "duration",
   "totalCallsArr",
   "internalCalls",
  ],
  data() {
   return {
    series: this.totalCallsArr,
    chartOptions: {
     colors: ["#0EA5E9", "#00E396", "#eab308"],
     title: {
      text: this.title,
      align: "center",
      floating: false,
      style: {
       fontSize: "14px",
       fontWeight: "bold",
       fontFamily: undefined,
       color: "#263238",
      },
     },
     chart: {
      fontFamily: "Nunito, sans-serif",
      type: "donut",
     },
     legend: {
      show: false,
      position: "bottom",
     },
     plotOptions: {
      pie: {
       donut: {
        size: "55%",
        labels: {
         show: true,
         value: {
          show: true,
          fontSize: "14px",
          fontWeight: 400,
          offsetY: 0,
          color: undefined,
          formatter: function (val) {
           return val + " appels";
          },
         },
         total: {
          show: true,
          showAlways: true,
          fontSize: "14px",
          fontWeight: 600,
          color: "#373d3f",
          formatter: function (w) {
           let t = w.globals.seriesTotals.reduce(
            (a, b) => parseInt(a) + parseInt(b)
           );
           return t >= 10000 ? (t / 1000).toFixed(1) + "K" : t;
          },
         },
        },
       },
      },
     },
     dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
       return val.toFixed(0) + "%";
      },
     },
     noData: {
      text: "Loading...",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
       color: undefined,
       fontSize: "14px",
       fontFamily: undefined,
      },
     },
     labels: ["Appels reçus", "Appel émis", "Appels internes"],
     responsive: [
      {
       breakpoint: 480,
       options: {
        chart: {
         width: 350,
        },
        legend: {
         position: "bottom",
        },
        plotOptions: {
         pie: {
          donut: {
           size: "55%",
          },
         },
        },
       },
      },
      {
       breakpoint: 768,
       options: {
        chart: {
         width: 400,
        },
        legend: {
         position: "bottom",
        },
        plotOptions: {
         pie: {
          donut: {
           size: "50%",
          },
         },
        },
       },
      },
     ],
    },
    chartOptionsDuration: {
     colors: ["#0EA5E9", "#00E396", "#eab308"],
     title: {
      text: this.title,
      align: "center",
      floating: false,
      style: {
       fontSize: "14px",
       fontWeight: "bold",
       fontFamily: undefined,
       color: "#263238",
      },
     },
     chart: {
      fontFamily: "Nunito, sans-serif",
      type: "donut",
     },
     legend: {
      show: false,
      position: "bottom",
     },
     plotOptions: {
      pie: {
       donut: {
        size: "55%",
        labels: {
         show: true,
         value: {
          show: true,
          fontSize: "14px",
          fontWeight: 400,
          offsetY: 0,
          color: undefined,
          formatter: function (val) {
           return val + " appels";
          },
         },
         total: {
          show: true,
          showAlways: true,
          fontSize: "14px",
          fontWeight: 600,
          color: "#373d3f",
          formatSecsToHourMinAndSecsNoDays(value) {
           let formated = undefined;
           if (value > 86400) {
            let quotient = Math.floor(value / 86400);
            let remainder = value % 86400;
            formated =
             parseInt(quotient) * 24 +
             parseInt(
              new Date(remainder * 1000).toISOString().substring(11, 13)
             ) +
             new Date(remainder * 1000).toISOString().substring(13, 19);
           } else {
            formated = new Date(value * 1000).toISOString().substring(11, 19);
           }
           return formated;
          },
          formatter: function (w) {
           let t = w.globals.seriesTotals.reduce(
            (a, b) => parseInt(a) + parseInt(b)
           );
 
           let time =
            (parseInt(this.formatSecsToHourMinAndSecsNoDays(t).split(":")[0])
             ? parseInt(this.formatSecsToHourMinAndSecsNoDays(t).split(":")[0]) +
               "h "
             : "") +
            (parseInt(this.formatSecsToHourMinAndSecsNoDays(t).split(":")[1])
             ? parseInt(this.formatSecsToHourMinAndSecsNoDays(t).split(":")[1]) +
               "min"
             : "");
           return time;
          },
         },
        },
       },
      },
     },
     dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
       return val.toFixed(0) + "%";
      },
     },
     tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      hideEmptySeries: true,
      y: {
       show: true,
       formatter: function (val) {
        let formated = undefined;
        if (val > 86400) {
         let quotient = Math.floor(val / 86400);
         let remainder = val % 86400;
         formated =
          parseInt(quotient) * 24 +
          parseInt(new Date(remainder * 1000).toISOString().substring(11, 13)) +
          new Date(remainder * 1000).toISOString().substring(13, 19);
        } else {
         formated = new Date(val * 1000).toISOString().substring(11, 19);
        }
        return formated;
       },
      },
     },
     noData: {
      text: "Loading...",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
       color: undefined,
       fontSize: "14px",
       fontFamily: undefined,
      },
     },
     labels: ["Appels reçus", "Appel émis", "Appels internes"],
     responsive: [
      {
       breakpoint: 480,
       options: {
        chart: {
         width: 350,
        },
        legend: {
         position: "bottom",
        },
        plotOptions: {
         pie: {
          donut: {
           size: "55%",
          },
         },
        },
       },
      },
      {
       breakpoint: 768,
       options: {
        chart: {
         width: 400,
        },
        legend: {
         position: "bottom",
        },
        plotOptions: {
         pie: {
          donut: {
           size: "50%",
          },
         },
        },
       },
      },
     ],
    },
   };
  },
  methods: {},
 };
 </script>
 
 <style></style>
 