<template>
  <div class="w-full bg-white rounded-lg dark:bg-gray-800 p-4 md:p-6">
   <div class="relative inline-flex items-center text-white">
    <!-- <label class="block text-gray-700 text-sm font-bold mb-2">{{ label }}</label> -->
    <!-- <select
     v-model="selected"
     @change="handleChange"
     class="block font-bold -ml-1 focus:outline-none outline-none rounded-md shadow-md text-sm bg-pale-sky">
     <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
     </option>
    </select> -->
    <div class="relative" ref="menu">
     <button
      @click="toggleMenu"
      class="inline-flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 bg-pale-sky rounded-md shadow-sm focus:outline-none hover:bg-gray-600 transition ease-in-out duration-150">
      <span>{{ selected }}</span>
      <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20">
       <path
        fill-rule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"></path>
      </svg>
     </button>
     <div
      v-if="isOpen"
      class="absolute left-0 mt-2 w-56 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg">
      <div class="py-1">
       <button
        @blur="isOpen = false"
        v-for="option in options"
        :key="option"
        @click="selectOption(option)"
        class="w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
        {{ option.label }}
       </button>
      </div>
     </div>
    </div>
    <div
     class="pointer-events-none absolute inset-y-0 flex items-center right-4">
     <!-- <ChevronDownIcon :class="`h-4 w-4 text-white`" aria-hidden="true" /> -->
    </div>
   </div>
   <div
    class="flex justify-between border-gray-200 border-b dark:border-gray-700 pb-3">
    <!-- <dl>
     <dt class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1">Answered calls</dt>
     <dd class="leading-none text-3xl font-bold text-gray-900 dark:text-white">{{ ((totalAnswered / (totalAnswered + totalUnanswered)) * 100).toFixed(0) }}%</dd>
    </dl> -->
    <!-- <div>
     <span class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">
      <svg class="w-2.5 h-2.5 mr-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4" />
      </svg>
      Since last month 23.5%
     </span>
    </div> -->
   </div>
 
   <div class="lg:flex justify-between py-3 gap-2">
    <dl>
     <dt
      class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1 capitalize">
      {{ $t("total") }}
     </dt>
     <dd class="leading-none text-xl flex gap-1">
      <span class="font-bold">{{ totalAnswered + totalUnanswered }}</span>
      appels
     </dd>
    </dl>
    <dl>
     <dt
      class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1 capitalize">
      {{ $t("answered") }}
     </dt>
     <dd class="leading-none text-xl flex gap-1">
      <span class="font-bold text-green-600 dark:text-green-400">{{
       totalAnswered
      }}</span>
      appels
      <span class="font-bold"
       >({{
        ((totalAnswered / (totalAnswered + totalUnanswered)) * 100).toFixed(0)
       }}%)</span
      >
     </dd>
    </dl>
    <dl class="">
     <dt class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1">
      {{ $t("unanswered") }}
     </dt>
     <dd class="leading-none text-xl flex gap-1">
      <span class="font-bold text-red-600 dark:text-red-500">{{
       totalUnanswered
      }}</span>
      appels
      <span class="font-bold"
       >({{
        ((totalUnanswered / (totalAnswered + totalUnanswered)) * 100).toFixed(0)
       }}%)</span
      >
     </dd>
    </dl>
   </div>
   <div class="pt-4" id="chart">
    <apexchart
     type="bar"
     height="430"
     :options="chartOptions"
     :series="this.seriesForColumn ? this.seriesForColumn : series"></apexchart>
   </div>
   <!-- <div class="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
    <div class="flex justify-between items-center pt-5">
     <Menu as="div" class="relative inline-block text-left">
      <div>
       <MenuButton class="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white capitalize">
        {{ quickPeriod }}
        <svg class="w-2.5 m-2.5 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
         <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
        </svg>
       </MenuButton>
      </div>
 
      <transition
       enter-active-class="transition ease-out duration-100"
       enter-from-class="transform opacity-0 scale-95"
       enter-to-class="transform opacity-100 scale-100"
       leave-active-class="transition ease-in duration-75"
       leave-from-class="transform opacity-100 scale-100"
       leave-to-class="transform opacity-0 scale-95"
      >
       <MenuItems class="absolute left-0 z-10 origin-top-right bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        <div class="py-2 text-sm text-gray-700 dark:text-gray-200">
         <MenuItem v-for="option in periodOptions" :key="option.title">
          <a @click="(periodValue = option.value), $emit('quickPeriod', option.title)" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white capitalize cursor-pointer">{{ $t(option.title) }}</a>
         </MenuItem>
        </div>
       </MenuItems>
      </transition>
     </Menu>
     <a href="/expert-statistics/answered/daily/queue" class="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2">
      Answered Report
      <svg class="w-2.5 h-2.5 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
      </svg>
     </a>
    </div>
   </div> -->
  </div>
 </template>
 
 <script>
 import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
 import { ChevronDownIcon } from "@heroicons/vue/outline";
 const periodOptions = [
  //  { title: "today", current: false, value: 0 },
  //  { title: "yesterday", current: true, value: 1 },
  { title: "last 7 days", current: false, value: 7 },
  { title: "last 30 days", current: false, value: 30 },
  //  { title: "last 90 days", current: false },
 ];
 
 export default {
  props: [
   "seriesForColumn",
   "categoriesForColumn",
   "width",
   "height",
   "colors",
   "title",
   "totalAnswered",
   "totalUnanswered",
   "quickPeriod",
   "selectedOption",
   "selectedValue",
  ],
  components: {
   Menu,
   MenuButton,
   MenuItem,
   MenuItems,
   ChevronDownIcon,
  },
  data() {
   return {
    isOpen: false,
    selected: "",
    options: [
     { label: "Vue par jour", value: "day" },
     { label: "Vue par heure", value: "hour" },
    ],
    periodValue: null,
    periodOptions,
    showList: false,
    chartOptions: {
     colors: ["#16a34a", "#E34B5F"],
     chart: {
      fontFamily: "Nunito, sans-serif",
      stacked: true,
      sparkline: {
       enabled: false,
      },
      type: "bar",
      width: "100%",
      height: 400,
      toolbar: {
       show: false,
      },
     },
     plotOptions: {
      bar: {
       horizontal: false,
       columnWidth: "80%",
       borderRadiusApplication: "end",
       borderRadius: 6,
       dataLabels: {
        position: "top",
       },
      },
     },
     legend: {
      show: true,
      position: "bottom",
     },
     dataLabels: {
      enabled: false,
     },
     stroke: {
      show: true,
      width: 0.5,
      colors: ["#fff"],
     },
     tooltip: {
      shared: true,
      intersect: false,
     },
     xaxis: {
      tickAmount: 31,
      labels: {
       hideOverlappingLabels: true,
       rotate: -70,
       rotateAlways: false,
       show: true,
       style: {
        fontFamily: "Inter, sans-serif",
        cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
       },
       formatter: function (value, timestamp, opts) {
        if (value.includes(":")) {
         value =
          value.slice(0, 2) +
          "-" +
          (value = "0" + (parseInt(value) + 1)).slice(-2);
         return value + "h";
        } else {
         if (value.length > 0) {
          let newDate = new Date(value);
          let year = newDate.getFullYear();
          let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
          let day = ("0" + newDate.getDate()).slice(-2);
          let formated = String(day) + "/" + String(month);
          return formated;
         }
        }
       },
      },
      categories: this.categoriesForColumn ? this.categoriesForColumn : [],
      axisTicks: {
       show: true,
      },
      axisBorder: {
       show: true,
      },
     },
     yaxis: {
      labels: {
       show: true,
       style: {
        fontFamily: "Inter, sans-serif",
        cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
       },
       formatter: function (val, data) {
        let index = data ? data.dataPointIndex : undefined;
        let total = undefined;
        if (index >= 0) {
         total = data.series[0][index] + data.series[1][index];
        }
        return val + (total ? ` (${((val / total) * 100).toFixed(0)}%)` : "");
       },
      },
     },
     grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
       left: 10,
       right: 10,
       top: -20,
      },
     },
    },
   };
  },
  methods: {
   toggleMenu() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
     document.addEventListener("click", this.closeMenuOnClickOutside);
    } else {
     document.removeEventListener("click", this.closeMenuOnClickOutside);
    }
   },
   selectOption(option) {
    this.selected = option.label;
    this.isOpen = false;
    document.removeEventListener("click", this.closeMenuOnClickOutside);
    this.$emit("input", option.value);
   },
   closeMenuOnClickOutside(event) {
    if (!this.$refs.menu.contains(event.target)) {
     this.isOpen = false;
     document.removeEventListener("click", this.closeMenuOnClickOutside);
    }
   },
   handleChange(event) {
    this.$emit("input", event.target.value);
   },
  },
  mounted() {
   this.$emit("setLoading", false);
   this.selected = this.options.filter(
    res => res.value == this.selectedValue
   )[0]["label"];
  },
  watch: {
   periodValue: function (val) {
    this.$emit("selectPeriod", val);
   },
  },
  updated() {},
 };
 </script>
 